import React from 'react'

function Display() {
  return (
    <div style={{width:'100%', height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}} >
        <div> Only Show on Desktop View </div>
    </div>
  )
}

export default Display
